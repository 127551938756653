


































































































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop, Watch } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import EntityType from '@/utils/enums/EntityType';
import Channel from '@/models/graphql/Channel';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
  },
})

/* eslint-disable no-underscore-dangle */
export default class MembersWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'MembersWidgetStore';

  @Prop({
    required: false,
    default: '',
  })
  private readonly title!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly subtitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly type!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly route!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly btnRoute!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly btnTitle!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private data!: CommunityUser[] | Channel[];

  private entityType = EntityType;

  private limitNumberOfElement = 0;

  private serializedData: {
    uid: string;
    src: string;
    title: string;
    subTitle: string;
    firstName: string;
    lastName: string;
  }[] = [];

  private get getSize(): number {
    let size = 8;
    switch (this.breakpoint.value) {
      case 'xs':
        size = 4;
        break;
      case 'sm':
        size = 6;
        break;
      case 'md':
        size = 5;
        break;
      case 'lg':
        size = 7;
        break;
      default:
        size = 8;
        break;
    }
    this.limitNumberOfElement = size - 1;
    this.setData();
    return size;
  }

  created(): void {
    this.setDataConfig();
  }

  private mounted(): void {
    this.setData();
  }

  @Watch('data')
  private setData(): void {
    this.serializedData = [];
    if (this.data) {
      if (this.type === EntityType.USER) {
        (this.data as CommunityUser[]).forEach((item, index) => {
          if (index <= this.limitNumberOfElement) {
            this.serializedData.push({
              uid: item.uid,
              src: FileResourceHelper.getFullPath(item.pictureFileResource, 'w96'),
              title: `${item.firstName} ${item.lastName}`,
              subTitle: '',
              firstName: item.firstName ?? '',
              lastName: item.lastName ?? '',
            });
          }
        });
      } else if (this.type === EntityType.CHANNEL) {
        (this.data as Channel[]).forEach((item, index) => {
          if (index <= this.limitNumberOfElement) {
            this.serializedData.push({
              uid: item.uid,
              src: FileResourceHelper.getFullPath(item.thumbnailFileResource, 'w96'),
              title: item.name ?? '',
              subTitle: this.$tc('actions.followers', item._followerCount, { followersCount: item._followerCount }),
              firstName: '',
              lastName: '',
            });
          }
        });
      }
    }
  }

  private onBtnClick(): void {
    if (this.btnRoute) {
      this.$router.push(this.btnRoute);
    }
  }

  private routing(route: string, entityUid?: string): void {
    let external = route.startsWith('//');
    if (external) {
      window.open(route, '_blank');
    } else {
      try {
        const url = new URL(route);
        external = !!url.protocol;
        if (external) {
          window.open(route, '_blank');
          return;
        }
      } catch {
        let r = route;
        const matches = route.match(/(%[a-zA-Z-_]+%)/gs);
        if (matches) {
          matches.forEach((m) => {
            const prop = m.replaceAll('%', '')
              .trim();
            if (['memberId', 'channelId'].includes(prop) && entityUid) {
              r = r.replaceAll(m, entityUid);
            }
            if (this.$route.params[prop]) {
              r = r.replaceAll(m, this.$route.params[prop]);
            }
          });
          this.$router.push(r);
          return;
        }
        if (r.endsWith('/')) {
          this.$router.push(`${r}${entityUid}`);
          return;
        }
        this.$router.push(`${r}/${entityUid}`);
      }
    }
  }

  private onClick(uid: string): void {
    if (this.route) {
      this.routing(this.route, uid);
    } else if (this.type === EntityType.USER) {
      this.$router.push({
        name: 'member-detail',
        params: { memberId: uid },
      });
    } else if (this.type === EntityType.CHANNEL) {
      this.$router.push({
        name: 'channel-detail',
        params: { channelId: uid },
      });
    }
  }
}
